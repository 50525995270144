<script>
import {ref, computed} from "vue";
import {useStore} from "vuex";
export default {
  setup(){
    const store     = useStore();
    const search    = ref("");
    const country   = ref("");
    const countries = store.state.countries;
    const users     = computed( () => store.state.users);
    const visUsers  = computed( () => {
      if(!users.value) return;
      const filtered = search.value ? users.value.filter(d => d.email.indexOf(search.value)!= -1  ) : users.value;
      return country.value ? filtered.filter(d => d.country == country.value) : filtered;
      // if(!country.value || !users.value) return users.value;
      // return users.value.filter(d => d.country == country.value)
    });

    store.dispatch("getUsers");
    return {
      users,// : visUsers.value,
      visUsers,
      search,
      country,
      countries
    }

  }
}
</script>

<template>
  <div v-if="users">
    <div class="row">
      <div class="col-12">
        <h2>Usuarios</h2>
        <p class="mb-4 pb-4">
          <router-link :to="{name : 'AddUserAdmin'}" class="od_btn_noindex">Agregar usuario</router-link>
        </p>
      </div>
    </div>
    
    <div class="row">
      <div class="col-12">
        <form>
          <div class="row">
            <div class="col-sm-10">
              <input
                v-model="search"
                class="od_search"
                type="text"
                name="item"
                placeholder="Buscar usuario"
              />
            </div>

            <div class="col-sm-2">
              <select v-model="country">
                <option value="">País</option>
                <option v-for="(c, i) in countries" :key="`opt-y-${i}`" :value="c.id">
                  {{ c.name }}
                </option>
              </select>
            </div>
          </div>
        </form>
      </div>
    </div>

    <!-- -->
    <div class="row">
      <div class="col-12">

      </div>
      <div class="col-12">
        <div class="table">
          <table class="table">
            <thead>
              <tr>
                <th>id</th>
                <th>Correo</th>
                <th>país</th>
                <th>tipo</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(user, i) in visUsers" :key="`user-${i}`">
                <td>
                  <strong>
                    {{user.id}} 
                  </strong>
                </td>
                <td>{{ user.email }}</td>
                <td>{{ user.country }}</td>
                <td>{{ user.type == 0 ? 'usr' : 'admin' }}</td>
                <td>
                  <router-link :to="{name : 'UpdateUserAdmin', params : {id : user.id}}" class="od_btn_noindex">Editar</router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!-- <div class="row">
      <div class="col-sm-4 offset-8">
        <form>
          <p class="od_pagination text-right">
            <span>
              <span v-if="page > 1" class="btn_prev_next">
                <a v-on:click.prevent="page = page - 1">Anterior &lt;</a>
              </span>
              <input v-model="page" type="number" min="1" :max="pages" />
            </span>
            /<span>{{ pages }} </span>
            <span v-if="page < pages" class="btn_prev_next">
              <a v-on:click.prevent="page = page + 1">Siguiente &gt;</a>
            </span>
          </p>
        </form>
      </div>
    </div> -->
  </div>
</template>